<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-black"></div>

    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3">
        <div class="row justify-content-center my-auto">
          <div class="col-md-8 col-lg-6 col-xl-4">
            <div class="text-center py-5">

              <!-- // Logo -->
              <div class="mb-2 mb-md-4">
                <div class="d-block auth-logo">
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-dark" />
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-light" />
                </div>
              </div>

              <div class="mb-4">
                <div class="mb-1">
                  <img src="@/assets/images/spocha-text-logo.png" alt=""
                       height="56" class="auth-logo-dark" />
                </div>
                <div class="text-white font-size-24">歓迎致します!</div>
              </div>

              <div class="mt-5 mb-4 mb-md-5">
                <div class="avatar-lg mx-auto">
                  <div class="avatar-title bg-light text-primary display-5 rounded-circle">
                    <i class="uil uil-envelope-alt"></i>
                  </div>
                </div>
              </div>

              <div>
                <h4 class="text-white-50">{{ $t('account.verifyYourEmail') }}</h4>
                <div class="mt-4 mb-5">
                  <router-link to="/login">
                    <b-button class="btn btn-info w-100">{{ $t('btn.ok') }}</b-button>
                  </router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>
